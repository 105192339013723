<template>
  <div>
    <v-layout wrap justify-center py-16>
      <v-flex xs12 py-16>
        <v-layout wrap justify-center>
          <v-flex xs12 sm10 pb-5 v-if="$route.query.type == 'MCQ'">
            <span style="color: red; font-family: poppinsbold; font-size: 25px"
              >We regret to inform you that you did not pass the initial MCQ
              part. Please consider revisiting the material and attempting the
              MCQs again for improvement.</span
            >
          </v-flex>
          <v-flex xs12 sm10 pb-5 v-if="$route.query.type == 'True/False'">
            <span style="color: red; font-family: poppinsbold; font-size: 25px">
              We regret to inform you that you did not pass the True or False
              questions part. Please consider revisiting the material and
              attempting the True or False questions again for improvement.
            </span>
          </v-flex>
          <v-layout wrap>
            <v-flex xs12>
              <span
                style="
                  color: #3cb043;
                  font-family: opensanssemibold;
                  font-size: 25px;
                "
              >
                {{ formattedTime }}</span
              >
              <br />
              <span
                style="
                  color: #000000;
                  font-family: poppinsregular;
                  font-size: 13px;
                "
                >Second</span
              >
              <br />
              <span
                style="color: black; font-family: poppinsbold; font-size: 20px"
                >You will be automatically redirected to the home</span
              >
            </v-flex>
          </v-layout>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      exam: [],
      dirty_form: true,
      showDialog: false,
      startTime: null,
      course: "",
      currentTime: 20000,
    };
  },
  computed: {
    formattedTime() {
      const remaining = this.currentTime;
      // const hours = Math.floor(remaining / 3600000)
      //   .toString()
      //   .padStart(2, "0");
      // const minutes = Math.floor((remaining % 3600000) / 60000)
      //   .toString()
      //   .padStart(2, "0");
      const seconds = Math.floor((remaining % 60000) / 1000)
        .toString()
        .padStart(2, "0");
      return `${seconds}`;
    },
  },
  created() {
    this.updateTimer();
  },
  beforeMount() {
    setTimeout(
      function () {
        this.$router.push({ path: "/" });
      }.bind(this),
      20000
    );
  },
  //   beforeRouteLeave(to, from, next) {
  //   if (from.name === "examFailed") {
  //       next(false);
  // }
  //   },
  methods: {
    updateTimer() {
      setInterval(() => {
        if (this.currentTime > 0) {
          this.currentTime -= 1000; // Subtract 1 second
        }
      }, 1000);
    },
  },
};
</script>